import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { store } from "./redux/store";
import { HelmetProvider } from 'react-helmet-async';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "./variables.css";
import SuspenseLoader from "./component/SuspenseLoader";
import DesktopModeWrapper from "./common/DesktopModeWrapper";
import { StateContextProvider } from "./component/dashboard/CustomHook/StateManager";
import { GlobalStateContextProvider } from "./redux/Global";
const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <>
    <DesktopModeWrapper>
      <StateContextProvider>
        <GlobalStateContextProvider>
        <Provider store={store}>
          <HelmetProvider>
            <React.Suspense fallback={<SuspenseLoader />}>
              <Router>
                <App />
              </Router>
            </React.Suspense>
          </HelmetProvider>
        </Provider>
        </GlobalStateContextProvider>
      </StateContextProvider>
    </DesktopModeWrapper>
  </>
  
  );
  

reportWebVitals();